<script setup>
import {computed, ref} from "vue"
import {initAtlaseek} from "@atlaseek/common"
import {OneNotifications, OneProgress, OneDialog} from "@jirizavadil/framework.one/ui"
import {useAppNotificationsStore} from "@jirizavadil/framework.one/ui/src/stores/appNotifications.js"
import {defineStore} from "pinia"
import {useAppStore} from "./stores/app.js"
import {useUserStore} from "./stores/user.js"
import {storeToRefs} from "pinia"
import {useRoute} from "vue-router"

const route = useRoute()

const appStore = useAppStore()
const userStore = useUserStore()

const {app} = storeToRefs(appStore)

const appNotificationsStore = useAppNotificationsStore({defineStore})()
const {appNotification} = appNotificationsStore

initAtlaseek({appId: "hub", appNotification})

const cmsData = ref(null)

const cmsPage = computed(() => {
  if (!cmsData.value) return
  return cmsData.value.items.find((item) => {
    // return item.meta?.model === "page" && item.fields?.slug === route.meta.contentKey
    return item.fields?.slug === route.meta.contentKey
  })
})

// sections that are not 'page'
const cmsGlobal = computed(() => {
  if (!cmsData.value) return
  // const sectionDirections = cmsData.value.items.find(
  //   (item) => item.meta?.model === "sectionDirections"
  // )
  // const faqCategories = cmsData.value.items.filter((item) => item.meta?.model === "faqCategory")
  // const faqs = cmsData.value.items.filter((item) => item.meta?.model === "faq")
  const banners = cmsData.value.items.filter((item) => item.meta?.model === "banner")
  return {
    // sectionDirections,
    // faqCategories,
    // faqs,
    banners,
  }
})

const cms = computed(() => {
  return {
    ...(cmsPage.value && {page: cmsPage.value}),
    ...(cmsGlobal.value && {global: cmsGlobal.value}),
  }
})

// fetch content
async function fetchContent() {
  const url = `https://static.platforma.one/orgs/atlaseek/spaces/app.hub.atlaseek.com/environments/main/locales/en-us/site.json`
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache", // TODO: remove this?
  })
  const data = await response.json()
  cmsData.value = data
  console.log(`generated: ${new Date(data.generated)}`)
}

fetchContent()
</script>

<template>
  <transition
    enter-active-class="transition-opacity duration-500"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-500"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      class="fixed inset-0 z-50 flex bg-sand-100 flex-center"
      v-if="!app.auth.initialUserCheckDone"
    >
      <OneProgress class="text-brown-500" :lucideSize="32" />
    </div>
  </transition>
  <div class="">
    <RouterView :cms />
  </div>
  <OneNotifications :appNotificationsStore />
</template>
