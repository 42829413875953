import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc} from "firebase/firestore"
import {usersGet} from "@atlaseek/common"

export const useUserStore = defineStore("user", () => {
  // state
  const user = ref({})
  const userAuth = ref({})
  const userAcl = ref([])
  const userSignedIn = ref(false)
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userLoaded = computed(() => status.value == "loaded")
  const userId = computed(() => user.value.id)
  // const userRoles = computed(() => user.value.roles)
  // const userPlan = computed(() => user.value.plan)
  const userIsPlatformAdmin = computed(
    () => !!containsRole(userAcl.value, "global", "user", "platformAdmin")
  )
  // const userIsCreator = computed(() => !!userRoles.value?.includes("creator"))
  // const userIsExplorer = computed(() => !!userRoles.value?.includes("explorer"))
  const userIsHubCreator = computed(
    () => !!containsRole(userAcl.value, "hub", "user", "hubCreator")
  )
  const userIsOrgMember = computed(() =>
    userPlansAll.value.some((plan) =>
      [
        "hubBusinessFree",
        "hubBusinessTrial",
        "hubBusinessBasic",
        "hubBusinessPlus",
        "hubBusinessEnterprise",
      ].includes(plan)
    )
  )
  const userPlansAll = computed(() => userAcl.value.map((item) => item.pl))
  const userIsMemberOfPayingOrg = computed(() =>
    userPlansAll.value.some((plan) =>
      ["hubBusinessBasic", "hubBusinessPlus", "hubBusinessEnterprise"].includes(plan)
    )
  )
  const userIsMemberOfTrialOrg = computed(() =>
    userPlansAll.value.some((plan) => ["hubBusinessTrial"].includes(plan))
  )

  // actions
  async function get({userId}) {
    const res = await usersGet(userId, {include: ["roles", "orgs"], notifySuccess: false})
    user.value = res.response.data
    status.value = "loaded"
  }

  async function unbind() {
    console.log("unbind")
    return await this.detach()
  }

  // function userAllowed(featureRoles) {
  //   if (!userSignedIn.value || !userLoaded.value) return false
  //   return userRoles.value.some((item) => featureRoles.includes(item))
  // }

  // async function update(args) {
  //   const data = args.data
  //   const uid = args.userId || userId.value
  //   console.log({uid})
  //   const userRef = doc(firestore, `users/${uid}`)
  //   const updateData = {
  //     ...data,
  //     "meta.updated": serverTimestamp(),
  //   }
  //   console.log({updateData})
  //   return await updateDoc(userRef, updateData)
  // }

  return {
    // state
    user,
    userAuth,
    userAcl,
    userSignedIn,
    status,
    unsubscribe,

    // getters
    userLoaded,
    userId,
    // userRoles,
    // userPlan,
    userIsPlatformAdmin,
    // userIsCreator,
    // userIsExplorer,
    userIsHubCreator,
    userIsOrgMember,
    userPlansAll,
    userIsMemberOfPayingOrg,
    userIsMemberOfTrialOrg,

    // actions
    get,
    unbind,
    // userAllowed,
    // update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}

function containsRole(aclArray, channel, scope, role) {
  return aclArray.some((item) => item.ch === channel && item.sc === scope && item.rl.includes(role))
}
