import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {useUserStore} from "./user.js"
import {storeToRefs} from "pinia"
import {messagingConversationsGet} from "@atlaseek/common"

export const useMessagingStore = defineStore("messaging", () => {
  const messaging = ref({
    conversations: [],
  })

  async function getConversations() {
    const userStore = useUserStore()
    const {userIsHubCreator} = storeToRefs(userStore)

    const res = await messagingConversationsGet(undefined, {
      filter: {
        hasMessages: userIsHubCreator.value ? true : false, // prevent hub creators from seeing empty conversations
      },
      include: ["org", "opportunity"],
      notifySuccess: false,
    })
    messaging.value.conversations = res.response.data
  }

  return {
    messaging,

    getConversations,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessagingStore, import.meta.hot))
}
