import {request} from "../request.js"

export const profilesCreate = async (data, opts) => {
  return request({
    endpoint: "profiles",
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesGet = async (profileId, opts) => {
  const filters = opts?.filters ? opts.filters : {}
  const isMultiple = profileId ? false : true
  const hasFilters = Object.keys(filters).length > 0

  const params = new URLSearchParams()

  if (opts.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  // const endpoint = profileId
  //   ? `profiles/${profileId}${query}`
  //   : `profiles${query}`

  // return request({
  //   endpoint,
  //   method: "GET",
  //   opts,
  // })

  // mutliple
  if (isMultiple) {
    // with filters
    if (hasFilters) {
      return request({
        endpoint: `profiles/filter${query}`,
        method: "POST",
        data: {filters},
        opts,
      })
    }
    // all
    else {
      return request({
        endpoint: `profiles${query}`,
        method: "GET",
        opts,
      })
    }
  }
  // single
  else {
    return request({
      endpoint: `profiles/${profileId}${query}`,
      method: "GET",
      opts,
    })
  }
}

export const profilesGetByUsername = async (username, opts) => {
  return request({
    endpoint: `profiles/username/${username}`,
    method: "GET",
    opts,
  })
}

export const profilesGetByUserId = async (userId, opts) => {
  return request({
    endpoint: `profiles/userId/${userId}`,
    method: "GET",
    opts,
  })
}

export const profilesGetByOrgId = async (orgId, opts) => {
  return request({
    endpoint: `profiles/orgId/${orgId}`,
    method: "GET",
    opts,
  })
}

export const profilesUpdate = async (profileId, data, opts) => {
  return request({
    endpoint: `profiles/${profileId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesDelete = async (profileId, opts) => {
  return request({
    endpoint: `profiles/${profileId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesTravelPlansCreate = async (profileId, data, opts) => {
  return request({
    endpoint: `profiles/${profileId}/travelPlans`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesTravelPlansGet = async (profileId, travelPlanId, opts) => {
  const endpoint = travelPlanId
    ? `profiles/${profileId}/travelPlans/${travelPlanId}`
    : `profiles/${profileId}/travelPlans`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesTravelPlansUpdate = async (
  profileId,
  travelPlanId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/travelPlans/${travelPlanId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesTravelPlansDelete = async (
  profileId,
  travelPlanId,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/travelPlans/${travelPlanId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesPlatformsCreate = async (profileId, data, opts) => {
  return request({
    endpoint: `profiles/${profileId}/platforms`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesPlatformsGet = async (profileId, platformId, opts) => {
  const params = new URLSearchParams()

  if (opts.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = platformId
    ? `profiles/${profileId}/platforms/${platformId}${query}`
    : `profiles/${profileId}/platforms${query}`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesPlatformsUpdate = async (
  profileId,
  platformId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesPlatformsDelete = async (profileId, platformId, opts) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesContentsGet = async (profileId, contentId, opts) => {
  const endpoint = contentId
    ? `profiles/${profileId}/contents/${contentId}`
    : `profiles/${profileId}/contents`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesPlatformsContentsCreate = async (
  profileId,
  platformId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesPlatformsContentsGet = async (
  profileId,
  platformId,
  contentId,
  opts
) => {
  const endpoint = contentId
    ? `profiles/${profileId}/platforms/${platformId}/contents/${contentId}`
    : `profiles/${profileId}/platforms/${platformId}/contents`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesPlatformsContentsUpdate = async (
  profileId,
  platformId,
  contentId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents/${contentId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesPlatformsContentsDelete = async (
  profileId,
  platformId,
  contentId,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents/${contentId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesPlatformsContentsMetricsSnapshotsCreate = async (
  profileId,
  platformId,
  contentId,
  contentKind,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents/${contentId}/metricsSnapshots/${contentKind}`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesPlatformsContentsMetricsSnapshotsGet = async (
  profileId,
  platformId,
  contentId,
  snapshotId,
  opts
) => {
  const endpoint = snapshotId
    ? `profiles/${profileId}/platforms/${platformId}/contents/${contentId}/metricsSnapshots/${snapshotId}`
    : `profiles/${profileId}/platforms/${platformId}/contents/${contentId}/metricsSnapshots`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesPlatformsContentsMetricsSnapshotsUpdate = async (
  profileId,
  platformId,
  contentId,
  snapshotId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents/${contentId}/metricsSnapshots/${snapshotId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesPlatformsContentsMetricsSnapshotsDelete = async (
  profileId,
  platformId,
  contentId,
  snapshotId,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/contents/${contentId}/metricsSnapshots/${snapshotId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesPlatformsMetricsSnapshotsCreate = async (
  profileId,
  platformId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/metricsSnapshots`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const profilesPlatformsMetricsSnapshotsGet = async (
  profileId,
  platformId,
  snapshotId,
  opts
) => {
  const endpoint = snapshotId
    ? `profiles/${profileId}/platforms/${platformId}/metricsSnapshots/${snapshotId}`
    : `profiles/${profileId}/platforms/${platformId}/metricsSnapshots`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const profilesPlatformsMetricsSnapshotsUpdate = async (
  profileId,
  platformId,
  snapshotId,
  data,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/metricsSnapshots/${snapshotId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const profilesPlatformsMetricsSnapshotsDelete = async (
  profileId,
  platformId,
  snapshotId,
  opts
) => {
  return request({
    endpoint: `profiles/${profileId}/platforms/${platformId}/metricsSnapshots/${snapshotId}`,
    method: "DELETE",
    opts,
  })
}

export const profilesReviewsGet = async (profileId, reviewId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = reviewId
    ? `profiles/${profileId}/reviews/${reviewId}${query}`
    : `profiles/${profileId}/reviews${query}`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}
