import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {profilesGetByUserId} from "@atlaseek/common"
import {useUserStore} from "./user"

export const useProfileStore = defineStore("profile", () => {
  // state
  const profile = ref({})
  const status = ref("init")

  // getters
  const profileLoaded = computed(() => status.value == "loaded")
  const profileId = computed(() => profile.value?.id)

  // actions
  async function get({userId} = {}) {
    const userStore = useUserStore()
    const id = userId || userStore.userId
    console.log("profile.js store | userId:", id)
    const res = await profilesGetByUserId(id, {notifySuccess: false})
    profile.value = res.response.data?.profile
    status.value = "loaded"
  }

  return {
    // state
    profile,
    status,

    // getters
    profileLoaded,
    profileId,

    // actions
    get,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
