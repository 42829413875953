<script setup>
import {computed, ref} from "vue"
import {useRoute} from "vue-router"
import {OneViewAdmin, OneButton} from "@jirizavadil/framework.one/ui"
import Logo from "../assets/img/logo/logo atlaseek hub en.svg?url"
import LogoIcon from "../assets/img/logo/icon atlaseek hub.svg?url"
import {auth} from "@/firebase"
import {signOut} from "firebase/auth"
import {useAppStore} from "../stores/app.js"
import {useNotificationsStore} from "../stores/notifications.js"
import {useOrgStore} from "../stores/org.js"
import {useUserStore} from "../stores/user.js"
import {useProfileStore} from "../stores/profile.js"
import {storeToRefs} from "pinia"

const props = defineProps({
  cms: Object,
})

const urlCDN =
  import.meta.env.VITE_ENVIRONMENT === "local_dev" && import.meta.env.VITE_USE_EMULATOR === "false"
    ? import.meta.env.VITE_URL_CDN_PRODUCTION
    : import.meta.env.VITE_URL_CDN

const route = useRoute()

const appStore = useAppStore()
const notificationsStore = useNotificationsStore()
const orgStore = useOrgStore()
const userStore = useUserStore()
const profileStore = useProfileStore()

const {app} = storeToRefs(appStore)
const {messagesUnreadCount} = storeToRefs(notificationsStore)
const {org} = storeToRefs(orgStore)
const {
  user,
  userAuth,
  userIsHubCreator,
  userIsPlatformAdmin,
  userIsOrgMember,
  userIsMemberOfPayingOrg,
  userIsMemberOfTrialOrg,
} = storeToRefs(userStore)
const {profile} = storeToRefs(profileStore)

/////////////////////
// temporary permissions - later handled by real permissions

// creators: show to platform admins and members of all orgs
const showCreators = computed(() => userIsPlatformAdmin.value || userIsOrgMember.value)

// businesses: show only to platform admins
const showBusinesses = computed(() => userIsPlatformAdmin.value)

// opportunities: show to platform admins, hub creators and members of paying orgs or trial orgs
const showOpportunities = computed(
  () =>
    userIsPlatformAdmin.value ||
    userIsHubCreator.value ||
    userIsMemberOfPayingOrg.value ||
    userIsMemberOfTrialOrg.value
)

// applications: show to platform admins, hub creators and members of paying orgs or trial orgs
const showApplications = computed(
  () =>
    userIsPlatformAdmin.value ||
    userIsHubCreator.value ||
    userIsMemberOfPayingOrg.value ||
    userIsMemberOfTrialOrg.value
)

// collaborations: show to platform admins, hub creators and members of paying orgs or trial orgs
const showCollaborations = computed(
  () =>
    userIsPlatformAdmin.value ||
    userIsHubCreator.value ||
    userIsMemberOfPayingOrg.value ||
    userIsMemberOfTrialOrg.value
)

// messages: show to platform admins, hub creators and members of paying orgs or trial orgs
const showMessages = computed(
  () =>
    userIsPlatformAdmin.value ||
    userIsHubCreator.value ||
    userIsMemberOfPayingOrg.value ||
    userIsMemberOfTrialOrg.value
)

// lists: show to platform admins, hub creators and members of paying orgs or trial orgs
// for now only show to platform admins and members of paying orgs or trial orgs
const showLists = computed(
  () => userIsPlatformAdmin.value || userIsMemberOfPayingOrg.value || userIsMemberOfTrialOrg.value
)

const menuItems = ref([
  // dashboard
  {
    title: "dashboard",
    icon: "Home",
    route: "/",
    views: ["DashboardView"],
  },

  // creators
  ...(showCreators.value
    ? [
        {
          title: "creators",
          icon: "Palette",
          route: "/creators",
          views: ["CreatorsView", "CreatorsDetailView"],
        },
      ]
    : []),

  // businesses
  ...(showBusinesses.value
    ? [
        {
          title: "businesses",
          icon: "Building2",
          route: "/businesses",
          views: [
            "BusinessesView",
            "BusinessesDetailView",
            // "BusinessesDetailOpportunityView",
            "BusinessesDetailOpportunityApplicationView",
          ],
        },
      ]
    : []),

  // opportunities
  ...(showOpportunities.value
    ? [
        {
          title: "opportunities",
          icon: "DollarSign",
          route: "/opportunities",
          views: [
            "OpportunitiesView",
            "OpportunitiesDetailView",
            "OpportunitiesDetailNewView",
            "BusinessesDetailOpportunityView", // temporary
            "OpportunitiesDetailApplicationView",
          ],
        },
      ]
    : []),

  // applications
  ...(showApplications.value
    ? [
        {
          title: "applications",
          icon: "Inbox",
          route: "/applications",
          views: ["ApplicationsView", "ApplicationsDetailView"],
        },
      ]
    : []),

  // messages
  ...(showMessages.value
    ? [
        {
          title: "messages",
          icon: "MessageCircle",
          route: "/messaging",
          views: [
            "MessagingView",
            "MessagingConversationsView",
            "MessagingConversationsDetailView",
            "MessagingConversationsNewView",
          ],
          badge: messagesUnreadCount,
        },
      ]
    : []),

  // collaborations
  ...(showCollaborations.value
    ? [
        {
          title: "collaborations",
          icon: "Handshake",
          route: "/collaborations",
          views: ["CollaborationsView", "CollaborationsDetailView", "CollaborationsDetailNewView"],
        },
      ]
    : []),

  // lists
  ...(showLists.value
    ? [
        {
          title: "lists",
          icon: "Bookmark",
          route: "/lists",
          views: ["ListsView", "ListsDetailView"],
        },
      ]
    : []),
])
const sidebarState = ref("expanded")

const menuButtons = computed(() => {
  // first 4 menu items
  return menuItems.value.slice(0, 5).map((item) => {
    return {
      ...item,
      active: item.views.includes(route.name),
    }
  })
})

const sidebarMenuItems = computed(() => {
  return menuItems.value.map((item) => {
    return {
      ...item,
      active: item.views.includes(route.name),
    }
  })
})

const viewAdminAttrs = computed(() => {
  if (["CreatorsView"].includes(route.name)) {
    return {
      mainScrollable: false,
      mainPadding: false,
    }
  } else if (
    ["MessagingConversationsDetailView"].includes(route.name) &&
    app.value?.viewport?.size === "mobile"
  ) {
    return {
      mainScrollable: false,
      mainPadding: false,
    }
  } else {
    return {}
  }
})

const sidebarProjects = computed(() => {
  if (!user.value?.orgs) return

  return user.value.orgs.map((o) => {
    console.log(o)
    return {
      id: o.id,
      title: o.name,
      // subtitle: "lala",
      // imageURL: org.logo,
      // route: `/orgs/${org.id}`,
      active: org.value?.id === o.id,
    }
  })
})

const userImageURL = computed(() => {
  const avatar = user.value?.avatar
  if (!avatar) return null

  const urlImages = `${urlCDN}/images`
  return `${urlImages}/${avatar.imageId}___w_240__x_1.jpg`
})

const profileImageURL = computed(() => {
  const avatar = profile.value?.avatar
  if (!avatar) return null

  const urlImages = `${urlCDN}/images`
  return `${urlImages}/${avatar.imageId}___w_240__x_1.jpg`
})

const sidebarUser = computed(() => {
  return {
    title: user.value?.name || "thy gracious name",
    subtitle: user.value?.email || "email",
    imageURL: userAuth.value?.photoURL || userImageURL.value || profileImageURL.value || null,
  }
})

const banner = computed(() => {
  if (!props.cms?.global?.banners) return
  let slug = null
  if (userIsOrgMember.value && userIsMemberOfPayingOrg.value === false) {
    slug = "menu-banner-org-unpaid"
  }
  return props.cms.global.banners.find((item) => item.fields.slug === slug)
})

const menuShowBackButton = computed(() => {
  // return route.name !== "DashboardView"
  return false
})

const showMenuMobile = computed(() => !["MessagingConversationsDetailView"].includes(route.name))

function onProjectSelected({project, e}) {
  console.log("project selected", project, e)

  const orgId = project.id

  org.value.id = orgId

  // save to local storage
  localStorage.setItem("orgId", orgId)

  // reload app TODO: reload stores instead
  location.reload()
}
</script>

<template>
  <OneViewAdmin
    :sidebarMenuItems
    :sidebarUser
    :sidebarProjects
    :sidebarShowProjectSelector="sidebarProjects?.length > 1"
    :menuShowBackButton
    :menuButtons
    :mainScrollable
    :showMenuMobile
    v-bind="viewAdminAttrs"
    @clickResizeHandle="sidebarState = $event"
    @projectSelected="onProjectSelected"
  >
    <template #logo>
      <div class="pb-4 pt-2" v-show="sidebarState === 'expanded'">
        <img :src="Logo" class="mx-auto h-auto w-48" />
      </div>

      <div class="pb-6 pt-2" v-show="sidebarState === 'collapsed'">
        <img :src="LogoIcon" class="mx-auto h-auto w-8" />
      </div>
    </template>

    <template #additionalContent>
      <!-- banner -->
      <div
        class="mb-4 flex flex-col items-start gap-4 rounded-2xl bg-violet-500 px-4 py-6 text-white"
        v-if="banner?.fields && sidebarState === 'expanded'"
      >
        <span class="text-xl font-bold">{{ banner.fields.title }}</span>
        <p class="">{{ banner.fields.paragraph }}</p>
        <OneButton
          :label="banner.fields.cta.title"
          kind="solid"
          variant="white"
          tag="a"
          :size="8"
          :href="banner.fields.cta.value"
          :target="banner.fields.cta.target"
          v-if="banner.fields?.cta"
        />
      </div>
    </template>

    <template #userPopup>
      <div class="p-0">
        <OneButton
          label="sign out"
          variant="error"
          kind="naked"
          rounded="xl"
          align="start"
          fullWidth
          @action="signOut(auth)"
        />
      </div>
    </template>

    <template #main>
      <RouterView />
    </template>
  </OneViewAdmin>
</template>
