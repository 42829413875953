import {request} from "../request.js"

export const authInit = async (data, opts) => {
  return request({
    endpoint: "auth",
    method: "POST",
    data: {data},
    opts,
  })
}

export const authSignInWithOTP = async (data, opts) => {
  return request({
    endpoint: "auth/otp",
    method: "POST",
    data: {data},
    opts,
  })
}
