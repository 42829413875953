<script setup>
import {ref, computed} from "vue"
import Logo from "../assets/img/logo/logo atlaseek hub en.svg"
import {OneInput, OneButton, OneIcon, OneToast} from "@jirizavadil/framework.one/ui"
import {useRoute} from "vue-router"
import {authInit, authSignInWithOTP} from "@atlaseek/common"
import {auth} from "@/firebase"
import {signInWithEmailAndPassword, signInWithCustomToken} from "firebase/auth"
import cloneDeep from "lodash/cloneDeep"

const route = useRoute()

const validationInit = {
  email: {
    status: false,
    statusMessage: false,
  },
  password: {
    status: false,
    statusMessage: false,
  },
  otp: {
    status: false,
    statusMessage: false,
  },
}

const email = ref(null)
const password = ref(null)
const otp = ref(null)
const progress = ref({submitInit: false, submitOTP: false})
const currentStage = ref("init") // progress | init | provideOTP
const submitShake = ref(false)
const validation = ref(cloneDeep(validationInit))

const arrivedFromMagicLink = computed(
  () => route.query.apiKey && route.query.mode && route.query.oobCode
)

const showLoginForm = computed(() => !arrivedFromMagicLink.value)

async function initAuth() {
  console.log("initAuth", email.value)

  if (!email.value) {
    shakeSubmit()
  }
  progress.value.submitInit = true

  const res = await authInit(
    {
      email: email.value,
      host: window.location.host,
      redirectUrl: import.meta.env.VITE_URL_HUB_LOGIN_VERIFY,
    },
    {notifySuccess: false}
  )

  // request OTP
  if ("provide-otp" === res.response.statusCode) {
    validation.value.email.status = "success"
    currentStage.value = "provideOTP"
  }

  // request password
  if ("provide-password" === res.response.statusCode) {
    validation.value.email.status = "success"
    currentStage.value = "providePassword"
  }

  progress.value.submitInit = false
}

async function signInUsingPassword() {
  if (!email.value || !password.value) {
    shakeSubmit()
    return
  }

  const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value).catch(
    (error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log("error", errorCode, errorMessage)
      validation.value.password.status = "error"
      shakeSubmit()
    }
  )

  if (userCredential) {
    const user = userCredential.user
    console.log("user", user)
  }
}

async function signInUsingOTP() {
  progress.value.submitOTP = true
  console.log("signInUsingOTP", otp.value)
  const res = await authSignInWithOTP(
    {
      email: email.value,
      code: otp.value,
    },
    {notifySuccess: false}
  )

  const status = res.response.statusCode
  const token = res.response.data?.token
  console.log({status, token})

  // received token -> sign in
  if ("otp-token" === status && token) {
    validation.value.otp.status = "success"
    const userCredential = await signInWithCustomToken(auth, token).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log("error", errorCode, errorMessage)
      validation.value.email.status = "error"
      shakeSubmit()
    })

    console.log({userCredential})
  }
  // error -> shake submit
  else {
    validation.value.otp.status = "error"
    shakeSubmit()
  }
  progress.value.submitOTP = false
}

function shakeSubmit() {
  submitShake.value = true
  setTimeout(() => {
    submitShake.value = false
  }, 500)
  return
}

function onSubmit() {
  // stage: init
  if (currentStage.value === "init") {
    initAuth()
  }
  // stage: provide password
  else if (currentStage.value === "providePassword") {
    signInUsingPassword()
  }
  // stage: provide OTP
  else if (currentStage.value === "provideOTP") {
    signInUsingOTP()
  }
}

function onClickWrongEmail() {
  currentStage.value = "init"
  validation.value = cloneDeep(validationInit)
  email.value = null
}
</script>

<template>
  <div class="flex h-screen w-screen items-center justify-center p-4 text-center">
    <!-- auth failed -->
    <div class="" v-if="['AuthFailedView'].includes($route.name)">
      <RouterView />
    </div>

    <!-- auth -->
    <div class="w-full" v-if="['AuthView', 'AuthVerifyView'].includes($route.name)">
      <div class="flex w-full flex-col items-center gap-10" v-if="showLoginForm">
        <Logo class="mx-auto h-auto w-48" v-if="'init' === currentStage" />

        <div class="xbg-sand-200 flex w-full max-w-80 rounded-3xl px-4 flex-center">
          <!-- stage: init -->
          <div
            class="flex grow flex-col gap-2"
            v-if="['init', 'providePassword'].includes(currentStage)"
          >
            <!-- <h2 class="mb-4 text-2xl font-bold text-brown-700">sign in</h2> -->

            <!-- email -->
            <OneInput
              v-model="email"
              name="email"
              label="email"
              placeholder="ava@zoo-black.com"
              type="username"
              :status="validation.email.status"
              :statusMessage="validation.email.statusMessage"
              :size="14"
              focus
              labelInside
              fullWidth
              @enter="onSubmit"
            />

            <!-- password -->
            <OneInput
              v-model="password"
              name="password"
              label="password"
              placeholder="password"
              type="password"
              container="input"
              :status="validation.password.status"
              :statusMessage="validation.password.statusMessage"
              :size="14"
              focus
              labelInside
              fullWidth
              @enter="onSubmit"
              v-if="currentStage == 'providePassword'"
            />

            <!-- submit -->
            <OneButton
              class="mt-3"
              variant="primaryViolet"
              :shake="submitShake"
              label="continue"
              :progress="progress.submitInit"
              @action="onSubmit"
            />
          </div>

          <!-- stage: provideOTP -->
          <div
            class="flex grow flex-col items-center gap-4"
            v-if="['provideOTP'].includes(currentStage)"
          >
            <OneIcon class="size-10 text-violet-500" lucide="MailCheck" />
            <div class="flex w-full max-w-80 flex-col gap-8">
              <!-- header -->
              <div class="">
                <h2 class="mb-0 text-2xl font-bold text-brown-700">check your inbox</h2>
                <p class="mt-2 text-lg text-brown-500">
                  to sign in, enter code sent to: <br /><span class="font-bold text-brown-700">{{
                    email
                  }}</span>
                </p>
                <span
                  class="cursor-pointer text-sm text-brown-300 hover:text-brown-500 hover:underline"
                  @click="onClickWrongEmail"
                  >wrong email?</span
                >
              </div>

              <!-- OTP form -->
              <div class="flex flex-col gap-2">
                <!-- otp -->
                <OneInput
                  v-model="otp"
                  :label="false"
                  placeholder="123456"
                  type="otp"
                  :status="validation.otp.status"
                  :statusMessage="validation.otp.statusMessage"
                  :size="14"
                  focus
                  labelInside
                  fullWidth
                  @enter="onSubmit"
                />

                <!-- submit -->
                <OneButton
                  class="mt-3"
                  variant="primaryViolet"
                  :shake="submitShake"
                  label="continue"
                  :progress="progress.submitOTP"
                  @action="onSubmit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
