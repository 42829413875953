import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {useUserStore} from "../stores/user"
import {useProfileStore} from "../stores/profile.js"
import {useAppStore} from "../stores/app"
import sink from "@/sink/index.js"
import {auth} from "@/firebase"
import {
  onAuthStateChanged,
  // signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
  setPersistence,
  // updatePassword,
  // fetchSignInMethodsForEmail,
  browserLocalPersistence,
  // signOut,
} from "firebase/auth"
import {
  initAtlaseek,
  usersRefreshedToken,
  messagingConversationsMessagesGet,
  realtime,
} from "@atlaseek/common"
import {getConfig} from "@atlaseek/common/api/init.js"
import {useAppNotificationsStore} from "@jirizavadil/framework.one/ui/src/stores/appNotifications.js"
import {defineStore} from "pinia"
import {useOrgStore} from "../stores/org"
import {useDictionaryStore} from "../stores/dictionary.js"
import {useNotificationsStore} from "@/stores/notifications.js"
import {useMessagingStore} from "@/stores/messaging.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {top: 0}
  },
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  // console.log("beforeEach", to, from)

  if (to.query.apiKey && to.query.mode && to.query.oobCode) {
    loginWithMagicLink(to.query)
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next({name: "AuthView"}) // Redirect to login if not authenticated
  } else if (userStore.user?.channelHubSignupFormShowAtNextLogin && to.name !== "OnboardingView") {
    console.log("channelHubSignupFormShowAtNextLogin -> redirecting to OnboardingView")
    next({name: "OnboardingView"})
  } else {
    next() // proceed normally
  }
})

router.afterEach((to, from) => {
  // log event
  sink.event("screen_view", {
    firebase_screen: to.name.replace("View", ""),
  })
})

onAuthStateChanged(auth, async (firebaseUser) => {
  console.log("onAuthStateChanged", firebaseUser)
  const appStore = useAppStore()
  const orgStore = useOrgStore()
  const userStore = useUserStore()
  const profileStore = useProfileStore()
  const dictionaryStore = useDictionaryStore()
  const notificationsStore = useNotificationsStore()
  const appNotificationsStore = useAppNotificationsStore({defineStore})()
  const messagingStore = useMessagingStore()

  const org = orgStore.org
  const app = appStore.app
  const {appNotification} = appNotificationsStore

  const currentRoute = router.currentRoute
  const currentRouteName = currentRoute.value.name
  const currentRouteRequiresAuth = currentRoute.value.meta.requiresAuth || false

  if (firebaseUser?.email) {
    console.log("signed in")
    userStore.userSignedIn = true
    userStore.userAuth = firebaseUser

    await initUser({firebaseUser, userStore, appNotification})

    const forceRefreshToken = userStore.user?.authForceRefreshIdToken
    if (forceRefreshToken) {
      await firebaseUser.getIdToken(true)
      await usersRefreshedToken(firebaseUser.uid, {notifySuccess: false})
      await initUser({firebaseUser, userStore, appNotification})
    }

    // get user profile
    await profileStore.get({userId: firebaseUser.uid})

    // get unread messages
    const {response} = await messagingConversationsMessagesGet("all", undefined, {
      filter: {unread: true},
      notifySuccess: false,
    })
    console.log(`📫`, response)
    notificationsStore.notifications.messages.unread = response.data

    // get from local storage
    // TODO: seve this in database ?
    const orgIdPreferred = localStorage.getItem("orgId")
    const preferredOrgIdIsInUsersOrgs = userStore.user?.orgs?.find((o) => o.id === orgIdPreferred)

    // set from local storage or first user org as default
    let orgId
    if (preferredOrgIdIsInUsersOrgs) {
      orgId = orgIdPreferred
    } else {
      orgId = userStore.user?.orgs?.[0]?.id || null
    }
    org.id = orgId
    console.log("setting scope org id:", orgId, userStore.user?.orgs)

    // re-init atlaseek api
    initAtlaseek({scope: {orgId}, appNotification})

    // connect socket
    const config = getConfig()
    realtime.init({
      url: import.meta.env.VITE_URL_API,
      handshake: {
        auth, // firebase auth
        token: await auth.currentUser.getIdToken(),
        params: {
          ...(config.appId && {channel: config.appId}),
          ...(config.scope.orgId && {scopeOrg: config.scope.orgId}),
          ...(localStorage.testMode && {testMode: localStorage.testMode}),
        },
      },
    })

    // subscribe to messaging events
    realtime.on("messaging.conversations.*.message", (payload) => {
      console.log("📫 new message", payload, currentRoute?.value?.params?.conversationId)
      const {conversationId, id: messageId} = payload

      // if the conversation is not open -> add to unread messages
      if (currentRoute?.value?.params?.conversationId !== conversationId) {
        notificationsStore.notifications.messages.unread.push(payload)
        appNotification({
          title: "New message",
          type: "info",
          delay: 2500,
        })

        // if this conversation is not loaded yet -> reload conversations (so it appears in conversations list without user having to refresh)
        const conversationLoaded = messagingStore.messaging.conversations.findIndex(
          (c) => c.id === conversationId
        )
        console.log({conversationLoaded})
        if (conversationLoaded === -1) {
          console.log("conversation not loaded -> reloading conversations")
          messagingStore.getConversations()
        }
      }

      // mark message as delivered
      console.log("marking message as delivered", {conversationId, messageId})
      realtime.emit(`messaging.conversations.${conversationId}.message.${messageId}.delivered`)
    })

    const redirectedFrom = router.currentRoute?.value?.redirectedFrom

    console.log({redirectedFrom})

    // load dictionary
    dictionaryStore.get()

    if (redirectedFrom && redirectedFrom.fullPath !== "/") {
      console.log("redirecting to:", redirectedFrom)
      router.push(redirectedFrom)
    } else {
      // go where supposed to go
      // else {
      // creator -> opportunities
      if (userStore.userIsHubCreator) {
        router.push({
          name: "OpportunitiesView",
        })
      }

      // org member -> creators
      else if (userStore.userIsOrgMember) {
        router.push({
          name: "CreatorsView",
        })
      }

      // admin -> creators
      else if (userStore.userIsPlatformAdmin) {
        router.push({
          name: "CreatorsView",
        })
      }

      // go to dashboard
      else {
        router.push({
          name: "DashboardView",
        })
      }
      // }
    }
  }
  // on sign out
  else if (currentRouteRequiresAuth && currentRouteName !== "AuthView") {
    console.log("signed out -> redirecting to AuthView")
    userStore.userAuth = {}
    userStore.userSignedIn = false
    await userStore.unbind()
    router.push({
      name: "AuthView",
    })
  }

  // mock some delay on dev machine
  if (import.meta.env.VITE_ENVIRONMENT === "local_dev") {
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
  app.auth.initialUserCheckDone = true
})

async function initUser({firebaseUser, userStore, appNotification}) {
  // get ACL claims from token
  const token = await firebaseUser.getIdTokenResult()
  const claims = token?.claims
  const acl = claims?.acl || []
  userStore.userAcl = acl
  console.log({acl})

  // re-init atlaseek api
  initAtlaseek({appId: "hub", firebaseUser, appNotification})

  // get user data
  // await userStore.bind({userId: firebaseUser.uid})
  await userStore.get({userId: firebaseUser.uid})
}

async function loginWithMagicLink(params) {
  console.log("loginWithMagicLink", params)
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // use email from URL -> can confirm login from another device
    const userEmail = params.em || window.localStorage.getItem("emailForSignIn")

    await setPersistence(auth, browserLocalPersistence)
    await signInWithEmailLink(auth, userEmail).catch((error) => {
      console.error("Error while login the user via the sign in link", error)
      router.push({name: "AuthFailedView", state: {error: error.code}})
    })
    window.localStorage.removeItem("emailForSignIn")
  } else {
    console.error("Login link malformed")
    return false
  }
}

export {router}
