import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useNotificationsStore = defineStore("notifications", () => {
  const notifications = ref({
    messages: {
      unread: [],
    },
  })

  const messagesUnreadCount = computed(() => notifications.value.messages.unread.length)

  return {
    notifications,
    messagesUnreadCount,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
