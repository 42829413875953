import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useOrgStore = defineStore("org", () => {
  // state
  const org = ref({
    id: null,
  })

  const orgId = computed(() => org.value.id)

  return {
    org,
    orgId
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrgStore, import.meta.hot))
}
