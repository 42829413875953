import {request} from "../request.js"

export const usersRefreshedToken = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}/refreshed-token`,
    method: "PATCH",
    opts,
  })
}

export const usersCreate = async (data, opts) => {
  return request({
    endpoint: "users",
    method: "POST",
    data: {data},
    opts,
  })
}

export const usersGet = async (userId, opts) => {
  const params = new URLSearchParams()

  if (opts.include) {
    params.append("include", opts.include)
  }

  if (opts.orgId) {
    params.append("orgId", opts.orgId)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = userId ? `users/${userId}${query}` : `users${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const usersUpdate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const usersDelete = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}`,
    method: "DELETE",
    opts,
  })
}

export const usersRolesCreate = async (userId, roleId, orgId, opts) => {
  const params = new URLSearchParams()

  if (orgId) {
    params.append("orgId", orgId)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  return request({
    endpoint: `users/${userId}/roles/${roleId}${query}`,
    method: "POST",
    opts,
  })
}

export const usersRolesGet = async (userId, roleId, orgId, opts) => {
  const params = new URLSearchParams()

  if (orgId) {
    params.append("orgId", orgId)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = roleId
    ? `users/${userId}/roles/${roleId}${query}`
    : `users/${userId}/roles${query}`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const usersRolesDelete = async (userId, roleId, orgId, opts) => {
  const params = new URLSearchParams()

  if (orgId) {
    params.append("orgId", orgId)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  return request({
    endpoint: `users/${userId}/roles/${roleId}${query}`,
    method: "DELETE",
    opts,
  })
}

export const usersInternalCreate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}/internal`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const usersInternalGet = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}/internal`,
    method: "GET",
    opts,
  })
}

export const usersInternalUpdate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}/internal`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const usersDetailsCreate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}/details`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const usersDetailsGet = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}/details`,
    method: "GET",
    opts,
  })
}

export const usersDetailsUpdate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}/details`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const usersPlatformsCreate = async (userId, platformId, data, opts) => {
  return request({
    endpoint: `users/${userId}/platforms/${platformId}`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const usersPlatformsGet = async (userId, platformId, opts) => {
  const endpoint = platformId
    ? `users/${userId}/platforms/${platformId}`
    : `users/${userId}/platforms`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const usersPlatformsUpdate = async (userId, platformId, data, opts) => {
  return request({
    endpoint: `users/${userId}/platforms/${platformId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const usersPasswordSet = async (userId, password, opts) => {
  console.log({userId, password})
  return request({
    endpoint: `users/${userId}/set-password`,
    method: "POST",
    data: {data: {password}},
    opts,
  })
}

export const usersCheckEmail = async (email, opts) => {
  return request({
    endpoint: `users/check/email/${email}`,
    method: "GET",
    opts,
  })
}

export const usersOnboardingGet = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}/onboarding`,
    method: "GET",
    opts,
  })
}

export const usersOnboardingUpdate = async (userId, stage, data, opts) => {
  const endpoint = stage ? `users/${userId}/onboarding/${stage}` : `users/${userId}/onboarding`
  return request({
    endpoint,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const isEmailAvailable = async (email) => {
  const response = await usersCheckEmail(email, {notify: false})
  return response.response.data.available
}
