import {request} from "../request.js"

export const messagingConversationsCreate = async (data, opts) => {
  return request({
    endpoint: "messaging/conversations",
    method: "POST",
    data: {data},
    opts,
  })
}

export const messagingConversationsGet = async (conversationId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append("include", opts?.include)
  }

  if (opts?.filter) {
    if (opts.filter.hasMessages) {
      params.append("filter[hasMessages]", opts.filter.hasMessages)
    }
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = conversationId
    ? `messaging/conversations/${conversationId}${query}`
    : `messaging/conversations${query}`
  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const messagingConversationsStatusUpdateDelivered = async (opts) => {
  return request({
    endpoint: `messaging/conversations/status/delivered`,
    method: "PATCH",
    opts,
  })
}

export const messagingConversationsMembersCreate = async (
  conversationId,
  data,
  opts
) => {
  return request({
    endpoint: `messaging/conversations/${conversationId}/members`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const messagingConversationsMessagesCreate = async (
  conversationId,
  data,
  opts
) => {
  return request({
    endpoint: `messaging/conversations/${conversationId}/messages`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const messagingConversationsMessagesGet = async (
  conversationId,
  messageId,
  opts
) => {
  const params = new URLSearchParams()

  if (opts?.filter) {
    if (opts.filter.unread) {
      params.append("filter[unread]", opts.filter.unread)
    }
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = messageId
    ? `messaging/conversations/${conversationId}/messages/${messageId}${query}`
    : `messaging/conversations/${conversationId}/messages${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const messagingConversationsMessagesStatusUpdate = async (
  conversationId,
  messageId,
  status,
  opts
) => {
  return request({
    endpoint: `messaging/conversations/${conversationId}/messages/${messageId}/${status}`,
    method: "PATCH",
    opts,
  })
}
