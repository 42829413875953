import MainView from "../views/MainView.vue"
import AuthView from "../views/AuthView.vue"

export const routes = [
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
    children: [
      {
        path: "verify",
        name: "AuthVerifyView",
        component: AuthView,
      },
      {
        path: "failed",
        name: "AuthFailedView",
        component: () => import("../views/AuthFailedView.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "creators",
        name: "CreatorsView",
        component: () => import("../views/CreatorsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":creatorId",
            name: "CreatorsDetailView",
            component: () => import("../views/CreatorsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "businesses",
        name: "BusinessesView",
        component: () => import("../views/BusinessesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":profileId",
            name: "BusinessesDetailView",
            component: () => import("../views/BusinessesDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "opportunities/:opportunityId",
                name: "BusinessesDetailOpportunityView",
                component: () => import("../views/OpportunitiesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: "application",
                    name: "BusinessesDetailOpportunityApplicationView",
                    component: () => import("../views/OpportunitiesDetailApplicationView.vue"),
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "opportunities",
        name: "OpportunitiesView",
        component: () => import("../views/OpportunitiesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "new",
            name: "OpportunitiesDetailNewView",
            component: () => import("../views/OpportunitiesDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":opportunityId",
            name: "OpportunitiesDetailView",
            component: () => import("../views/OpportunitiesDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "applications",
        name: "ApplicationsView",
        component: () => import("../views/ApplicationsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":applicationId",
            name: "ApplicationsDetailView",
            component: () => import("../views/ApplicationsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "collaborations",
        name: "CollaborationsView",
        component: () => import("../views/CollaborationsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "new",
            name: "CollaborationsDetailNewView",
            component: () => import("../views/CollaborationsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":collaborationId",
            name: "CollaborationsDetailView",
            component: () => import("../views/CollaborationsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "messaging",
        name: "MessagingView",
        component: () => import("../views/MessagingView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "conversations",
            name: "MessagingConversationsView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "conversations/:conversationId",
            name: "MessagingConversationsDetailView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "conversations/:conversationId?/messages/:messageId?",
            name: "MessagingConversationsMessagesView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "conversations/new",
            name: "MessagingConversationsNewView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "lists",
        name: "ListsView",
        component: () => import("../views/ListsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "new",
            name: "ListsDetailNewView",
            component: () => import("../views/ListsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":listId",
            name: "ListsDetailView",
            component: () => import("../views/ListsDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/onboarding/:entityId?/:stage?",
    name: "OnboardingView",
    component: () => import("../views/OnboardingView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {path: "", redirect: {name: "DashboardView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "DashboardView"}},
]
