function createRealtimeClient() {
  let socket = null
  let socketId = null
  let isConnecting = false
  let isConnected = false
  let reconnectAttempts = 0
  let maxReconnectAttempts = Infinity
  let reconnectInterval = 1000
  let connectionTimeout
  const topics = new Set()
  const events = new Map()
  const messageQueue = []
  let url = null
  let handshake = null

  function init({url: initUrl, handshake: initHandshake}) {
    url = initUrl
    handshake = initHandshake
    connect()
  }

  function connect() {
    if (isConnecting) return
    isConnecting = true
    console.log("🟡 WS connecting to", url)
    socket = new WebSocket(url)

    socket.onopen = handleOpen
    socket.onmessage = handleMessage
    socket.onerror = handleError
    socket.onclose = handleClose

    connectionTimeout = setTimeout(() => {
      if (!isConnected) {
        console.log("Connection attempt timed out")
        socket.close()
      }
    }, 10000)
  }

  function handleOpen() {
    clearTimeout(connectionTimeout)
    isConnected = true
    isConnecting = false
    console.log("🟢 WS connection opened")

    if (handshake) {
      const {token, params} = handshake
      sendMessage({type: "handshake", token, params})
    }

    if (reconnectAttempts > 0) {
      console.log(
        `Successfully reconnected after ${reconnectAttempts} attempts`
      )
      resubscribeToTopics()
      emitEvent("ws.reconnect", {attempts: reconnectAttempts})
      reconnectAttempts = 0
    }

    processQueue()
  }

  async function handleMessage(event) {
    try {
      const data = JSON.parse(event.data)
      const {type, topic, payload} = data

      if (type === "handshake") {
        const {success, id} = data
        if (success) {
          console.log("🟢 WS handshake", success)
          isConnected = true
          socketId = id
          emitEvent("ws.connect")
        } else {
          if (data.error === "auth/id-token-expired") {
            const {auth, params} = handshake
            // refresh firebase token
            const token = await auth.currentUser.getIdToken(true)
            console.log("🟢 WS token refreshed", token)
            sendMessage({type: "handshake", token, params})
          }
        }
      }

      if (type === "ping") {
        // console.log("🟡 WS ping", socketId)
        sendMessage({type: "pong", id: socketId})
      }

      if (type === "subscription") {
        emitEvent(topic, payload)
      }
    } catch (error) {
      console.error("Error parsing message:", error)
    }
  }

  function handleError(error) {
    console.error("WS error:", error)
    isConnected = false
    isConnecting = false
    emitEvent("ws.error", error)
  }

  function handleClose(event) {
    clearTimeout(connectionTimeout)
    isConnected = false
    isConnecting = false
    console.log("WS connection closed:", event.code, event.reason)
    emitEvent("ws.disconnect", event)
    attemptReconnect()
  }

  function attemptReconnect() {
    if (reconnectAttempts < maxReconnectAttempts) {
      reconnectAttempts++
      const delay = Math.min(30000, 1000 * Math.pow(2, reconnectAttempts))
      console.log(
        `Attempting to reconnect (${reconnectAttempts}/${maxReconnectAttempts})`
      )
      setTimeout(connect, delay)
    } else {
      console.log("Max reconnection attempts reached")
    }
  }

  // subscribe to events emitted by server or this module
  function on(event, listener) {
    console.log("on() -> subscribing to emits", event)
    subscribe(event)
    if (!events.has(event)) {
      events.set(event, [])
    }
    events.get(event).push(listener)
  }

  // unsubscribe from events emitted by server or this module
  function off(event, listener) {
    if (events.has(event)) {
      events.set(
        event,
        events.get(event).filter((l) => l !== listener)
      )
      unsubscribe(event)
    }
  }

  // emit events emitted by server or this module to listeners
  function emitEvent(event, data) {
    console.log("emitEvent() -> emitting event", event, data)
    if (events.has(event)) {
      events.get(event).forEach((listener) => listener(data))
    }
  }

  // emit to server
  function sendMessage(message) {
    if (isConnected && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message))
    } else {
      messageQueue.push(message)
      if (!isConnected && !isConnecting) {
        attemptReconnect()
      }
    }
  }

  function processQueue() {
    while (
      messageQueue.length > 0 &&
      isConnected &&
      socket.readyState === WebSocket.OPEN
    ) {
      const message = messageQueue.shift()
      socket.send(JSON.stringify(message))
    }
  }

  function emit(input) {
    // string -> treat as the topic
    if (typeof input === "string") {
      sendMessage({type: "subscription", topic: input})
    } else if (typeof input === "object" && input !== null) {
      const {type = "subscription", topic, data} = input
      sendMessage({type, topic, data})
    } else {
      console.error("Invalid input for emit function")
    }
  }

  // subscribe to topic -> server will send messages for this topic
  function subscribe(topic) {
    // ignore ws.* events (client only)
    if (topic.startsWith("ws.")) return
    topics.add(topic)
    emit({type: "subscribe", topic})
  }

  // unsubscribe from topic -> server will stop sending messages for this topic
  function unsubscribe(topic) {
    topics.delete(topic)
    emit({type: "unsubscribe", topic})
  }

  function close() {
    isConnected = false
    socket.close()
  }

  function resubscribeToTopics() {
    console.log("Resubscribing to topics:", Array.from(topics))
    topics.forEach((topic) => {
      emit({type: "subscribe", topic})
    })
  }

  return {
    init,
    on,
    off,
    emit,
    close,
    // unsubscribe,
    topics,
    events,
    messageQueue,
  }
}

export const realtime = createRealtimeClient()
